.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    grid-auto-rows: 1fr;
}

.grid .grid-item {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.grid .grid-item .content {
    width: 100%;
}

.grid .grid-item.card .card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}