// Import Bulma's core
@import '~bulma/sass/utilities/_all';

// Set your colors
$primary: #4d2a69;
$primary-invert: findColorInvert($primary);

$selected-row: #4a4a4a;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    'white': ($white, $black),
    'black': ($black, $white),
    'light': ($light, $light-invert),
    'dark': ($dark, $dark-invert),
    'primary': ($primary, $primary-invert),
    'info': ($info, $info-invert),
    'success': ($success, $success-invert),
    'warning': ($warning, $warning-invert),
    'danger': ($danger, $danger-invert)
);

$active-tab-border: #363636;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';

// Import Custom CSS
@import './Spacing.scss';
@import './Positioning.scss';
@import './RouterTransitions.scss';
@import './Grid.scss';

// Kara color blocks

$grey: #D6D4D7;
$light-grey: #F2F2F2;
$white: #FFFFFF;
$nyu-purple: #502785;
$light-purple: #663399;
$cancel-red: #A51022;
$dark-cancel-red: #7C0C19;

// end Kara color blocks

// Kara button css

@mixin button($type){
    position: relative;
    padding: 12px;
    font-weight: 500;
    width: fit-content;
    max-width: fit-content;
    cursor: pointer;
    color: $white;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0,0,0,.3);
    -webkit-border-radius: 0.75em; 
    -moz-border-radius: 0.75em;
    border-radius: 0.75em;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
    box-shadow: 0 1px 2px rgba(0,0,0,.2);

    &:hover, &.is-hovered {
        text-decoration: none;
        top: 1px;
        color: $white;
    }

    @if $type == 'basic' {
        background: $nyu-purple;
        border-color: $nyu-purple;
            
        &:hover {
            background: $light-purple;
        }
    }

    @if $type == 'cancel' {
        background: none;
        border: none;
        box-shadow: none;
        border-radius: 0;
        text-decoration: underline;
        color: $nyu-purple;
        
        &:hover {
            background: none;
            color: $light-purple;
        }
    }

    @if $type == 'red' {
        background: $cancel-red;
        border-color: $dark-cancel-red;
        &:hover {
            background: $dark-cancel-red;
        }
    }
}

button.button {
    @include button(basic);
}

// Kara button end css

// fixes label color in a selected table row
.table tr.is-selected td .label {
    color: currentColor;
}

// Changes tab colors from purple to a light gray
.tabs.is-toggle li.is-active a {
    background-color: $light;
    border-color: $active-tab-border;
    color: $light-invert;
}

.nested-input-is-danger input {
    border-color: $danger;
}

tr.can-select {
    cursor: pointer;
}

.b-tooltip {
    cursor: help;
    &.is-info {
        &.is-dashed {
            cursor: help;
            border-bottom: 1px dashed $info;
        }
    }
}

.icon {
    &.close {
        cursor: pointer;
    }
}

.table tr.is-selected {
    background-color: $selected-row;
    color: $dark-invert;
}

// TODO: clean up random styles added here

// smooth progress bar transition
.progress::-webkit-progress-value {
    transition: width 0.75s ease;
}

.is-borderless {
    border: 0;
}

.inherit-background-color {
    background-color: inherit;
}

*[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

// Sticky footer: https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/

.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.site-content {
    flex: 1;
    // setting the flex direction to column + the auto margin from container does
    // some weird squeezing on site content. Setting the Wwdth to 100% fixes this
    width: 100%;
}

.is-danger label,
.is-danger input,
.is-danger .taginput-container {
    border-color: #ff3860 !important;
}

// I needed to beat out another class in terms of css specificity which resulted in the length of this
// Allows us to have multiline radio buttons
.field.has-addons.is-grouped.is-grouped-multiline.radio-button
    > .control:not(:last-child) {
    margin-right: 0;
}

// Bulma's color helpers use important which is not exactly flexible
.background-white-ter {
    background-color: whitesmoke;
}

.no-wrap {
    white-space: nowrap;
}

.footer-border {
    border-top: 1px solid #a6a6a6;
}

.omit-header > div.table-wrapper > table > thead:nth-child(1) {
    display: none;
}

tr[draggable="true"] {
    cursor: pointer;
}

// courtesy of BootStrap

.well {
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    max-height: 50vh;
    overflow-y: scroll;
}

.grid.well article.grid-item {
    margin: 0;
}

.b-table.table-item, 
.b-table.table-item .table-wrapper, 
.b-table.table-item .table-wrapper table.table {
    height: 100%;
}

.b-table.table-item .table {
    border-collapse: collapse;
}

.b-table.table-item th {
    display: none;
}

.b-table.table-item td {
    padding: 0.5em;
}

// A selected row
.b-table.table-item tr.has-background-grey-lighter td {
    border: 1px solid $light;
}


@media screen and (max-width: 768px) {
    .table-item .table.has-mobile-cards tr:not(:last-child) {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 768px) {
    .is-fixed {
        position: fixed;
    }
}

// Drag & Dropd

$gap-border-width: 1.5rem 0 0 0;

tr.border-gap-top td {
    border-width: $gap-border-width;
}
