.slide-left-enter-active {
	animation-name: slide-in;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	opacity: 0;
}

.slide-left-leave-active {
	animation-name: slide-out;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: backwards;
	opacity: 1;
}

@keyframes slide-in {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes slide-out {
	100% {
		transform: translateX(100%);
		opacity: 0;
	}
}

@media screen and (min-width: 1024px) {
	@keyframes slide-in {
		0% {
			transform: translateX(66%);
		}
		100% {
			transform: translateX(0%);
			opacity: 1;
		}
	}

	@keyframes slide-out {
		100% {
			transform: translateX(66%);
			opacity: 0;
		}
	}
}

.squeeze {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
